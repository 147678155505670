import { Accordion, AccordionDetails, AccordionSummary, Box, Container, List, ListItem, ListItemIcon, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { ExtLink, Link } from 'components';
import { SupportAccordion } from './SupportAccordion';
import { SupportContact, SupportContactDetails } from './SupportContactDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Title } from './Title';
import CircleIcon from '@mui/icons-material/Circle';

export function Resources() {
  const theme = useTheme();

  return (
    <Container>
      <Typography
        color={cyan[700]}
        variant="h4"
        align="left"
        sx={{
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(2),
        }}
      >
        Useful Resources
      </Typography>

      <Box py={2} mb={8}>
        <Typography paragraph>
          If you are in an emergency situation or need immediate assistance, <u>contact emergency services on <b>000</b>.</u>
        </Typography>

        <SupportAccordion title="Crisis support helplines">
          <Title content="If you or your child need to speak with someone urgently (24 hours a day, 7 days a week)" />
          {crisisSupport.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
        </SupportAccordion>

        <SupportAccordion title="Mental health support">
          <Title content="In-person psychological support" />
          {inPersonPsychologicalSupport.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Title content="Telehealth psychological support and counselling" />
          If you want telehealth (online or phone) information and counselling for mental health issues, the following
          services may be suitable:
          {teleHealthPsychologicalSupport.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Box>
            <Typography my={theme.spacing(1)} variant="h6">
              <ExtLink color={cyan[700]} href="https://au.reachout.com">
                ReachOut
              </ExtLink>{' '}
              &{' '}
              <ExtLink color={cyan[700]} href="https://parents.au.reachout.com">
                ReachOut Parents
              </ExtLink>
            </Typography>
            <Typography>
              Reachout provides self-help information, referral tools, and a peer-support program for young people and their parents.
            </Typography>
          </Box>
          <SupportContactDetails
            support={{
              link: 'https://www.sane.org/get-support/drop-in-service',
              title: 'Sane Australia Mental Health Helpline',
              description:
                'SANE Australia’s Mental Health Helpline provides free telehealth support, information and referrals.',
              phone : '1800 187 263',
              openingHours : '10am – 8pm, Monday to Friday'
            }}
          />
          <SupportContactDetails
            support={{
              link: 'https://someone.health/',
              title: 'Someone Health',
              description:
                'Someone Health provides Medicare bulk billing psychologist counselling Australia wide by video and telephone.',
                phone : '1300 546 821',
            }}
          />
        </SupportAccordion>

        <SupportAccordion title="Parenting support lines by state">
          {parentLinkContacts.map(({ title, link, phone, openHours }, index) => (
            <Box key={index}>
              <Typography mt={theme.spacing(1)}>
                <ExtLink color={cyan[700]} href={link}>
                  {title}
                </ExtLink>
              </Typography>
              <Typography>
                Phone: <ExtLink href={`tel:${phone}`}>{phone}</ExtLink><span>{', '}{openHours}</span>
              </Typography>
            </Box>
          ))}
           <Box >
              <Typography mt={theme.spacing(1)}>
                <ExtLink color={cyan[700]} href={'https://www.csmc.org.au/'}>
                  Council of Single Mothers and their Children
                </ExtLink>
              </Typography>
              <Typography>
                Phone: <ExtLink href={`tel:${'03 9654 0622'}`}>{'03 9654 0622'}</ExtLink>{' (Melbourne Metro) or '}<ExtLink href={`tel:${'1300 552 511'}`}>{'1300 552 511'}</ExtLink>{' (outside Metro Melbourne)'}<span>{', '}{'9.30am – 3pm Monday to Friday'}</span>
              </Typography>
            </Box>
          <Box>
            <Typography mt={theme.spacing(1)}>
              <ExtLink color={cyan[700]} href="https://www.ngala.com.au/parenting-line/">
                WA Ngala Parenting Line
              </ExtLink>
            </Typography>
            <Typography>
              Phone Metro: <ExtLink href="tel:0893689368">08 9368 9368</ExtLink> <span>{', '} 8am-8pm, 7 days</span>
              <br />
              Phone Regional: <ExtLink href="tel:1800111546">1800 111 546</ExtLink> <span>{', '} 8am-8pm, 7 days</span>
            </Typography>
          </Box>
          <Box >
              <Typography mt={theme.spacing(1)}>
                <ExtLink color={cyan[700]} href={'https://raisingchildren.net.au/_media/external-links/w/wanslea-parents-grandparents'}>
                  Wanslea – Grandcare 
                </ExtLink> <span> (Information and support service for grandparents)</span>
              </Typography>
              <Typography>
                Phone: <ExtLink href={`tel:08 9245 2441`}>08 9245 2441</ExtLink><span>{', '}8am – 5pm, Monday to Friday</span>
              </Typography>
            </Box>
        </SupportAccordion>

        <SupportAccordion title="Additional resources">
          {additionalResources.map(({ title, sections }, index) => (
            <Box key={index} mt={theme.spacing(2)}>
              <Typography variant="h6" fontWeight="bold">
                {title}
              </Typography>
              {sections.map((section, sectionIndex) => (
                <Box key={sectionIndex} mt={theme.spacing(1)}>
                  <Typography
                    variant="body2"
                    fontStyle="italic"
                    style={{ paddingBottom: 10 }}
                  >
                    {section.subTitle}
                  </Typography>
                  {section.links.map(({ prefix, href, text, suffix, sub_links }, linkIndex) => (
                    <Box key={linkIndex}>
                      {prefix === 'School holiday camps:' ? (
                      <Typography>
                        {`School holiday camps: `}
                        <ExtLink color={cyan[700]} href={'https://www.flyingfox.org.au/'}>
                         Flying Fox Camps
                        </ExtLink>
                        {` and `}
                        <ExtLink color={cyan[700]} href={'https://www.autismcampaustralia.org/'}>
                         Autism Camps Australia
                        </ExtLink>
                      </Typography>
                      ) : (
                      <Typography>
                        {prefix && `${prefix} `}
                        <ExtLink color={cyan[700]} href={href}>
                          {text}
                        </ExtLink>
                        {suffix && `${suffix}`}
                      </Typography>
                      )}
                      {sub_links && sub_links.length > 0 && (
                        <Box pl={theme.spacing(2)} style={{ paddingBottom: 10 }} mt={theme.spacing(1)}>
                          {sub_links.map(({ text: subText, href: subHref }, subLinkIndex) => (
                            <Typography key={subLinkIndex}>
                              <ExtLink color={cyan[700]} href={subHref}>
                                {subText}
                              </ExtLink>
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ))}
        </SupportAccordion>

        <SupportAccordion title="Additional PDF resources">
        {pdfResources.map(({ title, sections }, index) => (
            <Box key={index} mt={theme.spacing(2)}>
              <Typography
                    variant="body2"
                    fontStyle="italic"
                    style={{ paddingBottom: 10 }}
                  >
                    {title}
                  </Typography>
              {sections.map((section, sectionIndex) => (
                <Box key={sectionIndex} mt={theme.spacing(1)}>
                  <Typography variant="h6" fontWeight="bold"
                    style={{ paddingBottom: 10 }}
                  >
                    {section.subTitle}
                  </Typography>
                  {section.links.map(({ prefix, href, text, suffix, sub_links }, linkIndex) => (
                    <Box key={linkIndex}>
                      <Typography>
                        {prefix && `${prefix} `}
                        <ExtLink color={cyan[700]} href={href}>
                          {text}
                        </ExtLink>
                        {suffix && ` ${suffix}`}
                      </Typography>
                      {sub_links && sub_links.length > 0 && (
                        <Box pl={theme.spacing(2)} style={{ paddingBottom: 10 }} mt={theme.spacing(1)}>
                          {sub_links.map(({ text: subText, href: subHref }, subLinkIndex) => (
                            <Typography key={subLinkIndex}>
                              <ExtLink color={cyan[700]} href={subHref}>
                                {subText}
                              </ExtLink>
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ))}
        </SupportAccordion>

        <SupportAccordion title="Further Reading">
        {furtherReadings.map(({ title, sections }, index) => (
            <Box key={index} mt={theme.spacing(2)}>
              <Typography variant="h6" fontWeight="bold">
                {title}
              </Typography>
              {sections.map((section, sectionIndex) => (
                <Box key={sectionIndex} mt={theme.spacing(1)}>
                  <Typography
                    variant="body2"
                    fontStyle="italic"
                    style={{ paddingBottom: 10 }}
                  >
                    {section.subTitle}
                  </Typography>
                  {section.links.map(({ prefix, href, text, suffix, sub_links }, linkIndex) => (
                    <Box key={linkIndex}>
                      <Typography>
                        {prefix && `${prefix} `}
                        <ExtLink color={cyan[700]} href={href}>
                          {text}
                        </ExtLink>
                        {suffix && ` ${suffix}`}
                      </Typography>
                      {sub_links && sub_links.length > 0 && (
                        <Box pl={theme.spacing(2)} style={{ paddingBottom: 10 }} mt={theme.spacing(1)}>
                          {sub_links.map(({ text: subText, href: subHref }, subLinkIndex) => (
                            <Typography key={subLinkIndex}>
                              <ExtLink color={cyan[700]} href={subHref}>
                                {subText}
                              </ExtLink>
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ))}
        </SupportAccordion>

      </Box>
    </Container>
  );
}

const crisisSupport: SupportContact[] = [
  {
    title: 'Kids Helpline',
    description:
      'A free, private, telephone and online counselling service specifically for young people aged 5 to 25 years.',
    link: 'https://kidshelpline.com.au/',
    phone: '1800 55 1800',
  },
  {
    title: 'Lifeline',
    description: 'Lifeline provides access to crisis support, suicide prevention and mental health support services.',
    link: 'https://www.lifeline.org.au/',
    phone: '13 11 14',
  },
  {
    title: 'Suicide Call Back Service',
    description:
      'Suicide Call Back Service provides 24/7 telephone and online counselling to people affected by suicide.',
    link: 'https://www.suicidecallbackservice.org.au/',
    phone: '1300 659 467',
  },
  
];

const inPersonPsychologicalSupport: SupportContact[] = [
  {
    title: 'Find a Psychologist',
    link: 'https://psychology.org.au/find-a-psychologist',
    description: (
      <>
        If you or your young person would like to find a registered psychologist in your area, the Australian Psychological Society and{' '}
        <ExtLink href="https://aapi.org.au/FindaPsychologist">Australian Association of Psychologists Inc</ExtLink>{' '}
        Find a Psychologist service can assist with this.
      </>
    ),
  },
  {
    title: 'Medicare-subsidised psychology sessions with a mental health treatment plan',
    link: 'https://www.healthdirect.gov.au/mental-health-treatment-plan',
    description:
      'A Mental Health Treatment Plan (MHTP) is a plan provided by a general practitioner (GP) to support someone with mental health issues. A MHTP can include referral to a mental health professional, including registered psychologists, psychiatrists, social workers and occupational therapists. If you have a MHTP, Medicare will pay some or all of the cost of a limited number of sessions each year. The out of pocket cost varies for different professionals or clinics.',
  }
];

const teleHealthPsychologicalSupport: SupportContact[] = [
  {
    title: 'Beyond Blue',
    link: 'http://www.beyondblue.org.au/getsupport',
    description:
      'Learn more about depression and anxiety, or talk it through with support services at Beyond Blue. Email or chat online.',
    phone: '1300 224 636',
    openingHours : '24 hours, 7 days'
  },
  {
    title: 'Mensline Australia',
    link: 'https://mensline.org.au/',
    description:
      'Support and referral to specialist men’s services.',
    phone: '1300 789 978',
     openingHours : '24 hours, 7 days'
  },
];

const parentLinkContacts = [
  { title: 'ACT Parentline', phone: '02 6287 3833', link: 'http://parentlineact.org.au', 
    openHours : '9am – 4pm, Monday to Friday (except public holidays)' },
  { title: 'NSW Parent Line', phone: '1300 130 052', link: 'https://www.parentline.org.au',
    openHours : '9am – 9pm, Monday to Friday or 4pm – 9pm weekends' 
   },
  { title: 'NT Parentline', phone: '1300 30 1300', link: 'https://parentline.com.au/',
    openHours : '8am – 10pm, 7 days'
  },
  { title: 'FACES Family Support Line NT', phone: '1800 999 900', link: 'https://www.ntcommunity.org.au/service/department-of-territory-families-housing-and-community-family-and-children-enquiry-support-faces/',
    openHours : '8am – 8pm, Monday to Friday'
  },
  { title: 'QLD Parentline', phone: '1300 30 1300', link: 'https://parentline.com.au/',
    openHours : '8am – 10pm, 7 days'
  },
  { title: '13 HEALTH', phone: '13 43 25 84', link: 'https://www.qld.gov.au/health/contacts/advice/13health',
    openHours : 'Speak to a registered nurse 24 hours 7 days or request the Child Health Team 6.30am – 11pm, 7 days'
  },
  { title: 'SA Parent Helpline', phone: '1300 364 100', link: 'https://www.cafhs.sa.gov.au/services/parent-helpline',
     openHours : '7.15am – 9.15pm, 7 days (calls outside these times will be redirected to the national Health Direct helpline)'
   },
  {
    title: 'TAS Parent Line',
    phone: '1300 808 178',
    link: 'https://www.health.tas.gov.au/health-topics/childrens-health/child-health-and-parenting-service-chaps/parent-line',
     openHours : '24 hours, 7 days'
  },
  { title: 'VIC Parentline', phone: '13 22 89', link: 'https://services.dffh.vic.gov.au/parentline',
    openHours  :'8am – 12am, 7 days'
   }
];

const onlinePrograms: SupportContact[] = [
  {
    title: 'Moodgym',
    description:
      'Moodgym is an interactive program based on Cognitive Behaviour Therapy and Interpersonal Therapy. It can help you to identify the mental health difficulties and to learn skills to cope with your emotions.',
    link: 'https://moodgym.com.au/',
  },
  {
    title: 'This Way Up',
    description:
      'This Way Up provides a suite of self-paced online programs for a range of mental health problems. The programs can teach you strategies to help improve the way you feel.',
    link: 'https://thiswayup.org.au/',
  },
  {
    title: 'Mindspot',
    description:
      'Mindspot provides access to free online and telephone mental health assessments, treatment programs, and a resource library for a range of mental health problems.',
    link: 'https://mindspot.org.au/',
    phone: '1800614434',
  },
];

const mentalHealthInformationForYoungPeople: SupportContact[] = [
  {
    title: 'Youth Beyond Blue',
    link: 'http://www.beyondblue.org.au/who-does-it-affect/young-people',
    description:
      'Youth Beyond Blue’s website is for young people aged 12 to 15. If you need to talk to someone, find more information, or find out where you can go to see someone contact the beyondblue support service.',
    phone: '1300 22 4636',
  },
  {
    title: 'headspace',
    link: 'http://www.headspace.org.au',
    description:
      'Headspace helps young people aged 12 to 25 who are going through a tough time, providing support for problems like depression, anxiety, bullying and body image.',
    phone: '1800 650 890',
  },
];

const onlineAndPhoneCounsellingForYoungPeople: SupportContact[] = [
  {
    title: 'eheadspace',
    link: 'https://headspace.org.au/online-and-phone-support/',
    description:
      'eheadspace is a confidential, free and secure space where young people aged 12 to 25 or their family can chat, email or speak on the phone with a qualified youth mental health professional.',
  },
  {
    title: 'Kids Helpline',
    link: 'https://kidshelpline.com.au/',
    description:
      'A free, private and confidential, telephone and online counselling service specifically for young people aged between 5 and 25.',
    phone: '1800 55 1800',
  },
];

const supportRelatingToGenderIdentitySexualityForYoungPeople: SupportContact[] = [
  {
    title: 'QLife',
    link: 'https://qlife.org.au',
    description:
      'Australia-wide, anonymous, peer support and referral for people wanting to talk about issues relating to sexuality, identity, gender, bodies, feelings, or relationships. Phone and web-chat available.',
  },
  {
    title: 'qheadspace',
    link: 'http://www.headspace.org.au/online-and-phone-support/spaces/community/372936',
    description: 'A peer led online chat for LGBTQIA+ young people in Australia.',
  },
  {
    title: 'Reachout Peer Chat',
    link: 'https://au.reachout.com/peerchattps://au.reachout.com',
    description:
      'If you have mental health challenges or something on your mind that you would like to chat about, you can book a free and confidential text-based chat with a peer worker.',
  },
];

const stateLegalAid = [
  { state: 'ACT', link: 'http://www.legalaidact.org.au/', description: 'Legal Aid Australian Capital Territory' },
  { state: 'NSW', link: 'http://www.legalaid.nsw.gov.au/', description: 'Legal Aid New South Wales' },
  { state: 'NT', link: 'https://www.legalaid.nt.gov.au/', description: 'Northern Territory Legal Aid Commission' },
  { state: 'QLD', link: 'http://www.legalaid.qld.gov.au/Home', description: 'Legal Aid Queensland' },
  { state: 'SA', link: 'http://www.lsc.sa.gov.au/', description: 'Legal Services Commission of South Australia' },
  { state: 'TAS', link: 'http://www.legalaid.tas.gov.au/', description: 'Legal Aid Commission of Tasmania' },
  { state: 'VIC', link: 'http://www.legalaid.vic.gov.au/', description: 'Victoria Legal Aid' },
  { state: 'WA', link: 'https://www.legalaid.wa.gov.au/', description: 'Legal Aid Western Australia' },
];

const additionalResources = [
  {
    title : "Assessment Services",
    sections : [
      {
        subTitle : "For information about assessment and intervention services, check out:",
        links : [
          {
            prefix : '',
            href : 'https://clinikids.telethonkids.org.au/',
            text : 'CliniKids',
            suffix : ' - The Kids Research Institute Australia (WA)',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.latrobe.edu.au/otarc/margot-prior-autism-intervention-centre',
            text : 'Margot Prior Autism Intervention Centre',
            suffix : ' (VIC, LaTrobe University)',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.latrobe.edu.au/otarc/veac',
            text : 'Victoria Early Intervention Clinic',
            suffix : ' (VIC, Olga Tennison Autism Research Centre)',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.betterhealth.vic.gov.au/health/servicesandsupport/autism-spectrum-disorder-support-services',
            text : 'Better Health Channel',
            suffix : ' - Provide information on government-funded teams that specialise in the assessment and diagnosis of ASD(VIC)',
            sub_links : []
          }
        ]
      }
    ]
  },
  {
    title : "Australian Guide to Healthy Eating and Physical Activity",
    sections : [
      {
        subTitle : "For the Australian guides to healthy eating and physical activity please check out:",
        links : [
          {
            prefix : '',
            href : 'https://www.eatforhealth.gov.au/guidelines/australian-guide-healthy-eating',
            text : 'Australian Guide to Healthy Eating - chart',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.eatforhealth.gov.au/sites/default/files/files/the_guidelines/n55f_children_brochure.pdf',
            text : 'Australian Healthy Eating for Children - guidelines and serving sizes',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.health.gov.au/topics/physical-activity-and-exercise/physical-activity-and-exercise-guidelines-for-all-australians/for-children-and-young-people-5-to-17-years',
            text : 'Australian Physical Activity Guidelines - guidelines for children 5-17 years',
            suffix : '',
            sub_links : []
          }
        ]
      }
    ]
  },
  {
    title : "Autism Language",
    sections : [
      {
        subTitle : "For more information about autism and language check out:",
        links : [
          {
            prefix : '',
            href : 'https://autismnz.org.nz/autism-new-zealand-terminology-guide/',
            text : 'Autism Terminology Resource - New Zealand',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.amaze.org.au/about-amaze/our-language/',
            text : 'Amaze - Our Language',
            suffix : '',
            sub_links : []
          }
        ]
      }
    ]
  },
  {
    title : "Building Supports and Connecting with Others",
    sections : [
      {
        subTitle : "For ideas and apps that can be used to connect with others, please see:",
        links : [
          {
            prefix : '',
            href : 'https://hello4health.org/activities/making-a-connection-capsule/',
            text : 'Making a Connection Capsule',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.myautismteam.com/',
            text : 'MyAutismTeam',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.kindship.com.au/',
            text : 'Kindship',
            suffix : ' (formerly known as AbleFinder)',
            sub_links : []
          }
        ]
      },
      {
        subTitle : "To read blogs by other parents, please see:",
        links : [
          {
            prefix : '',
            href : 'https://www.findingcoopersvoice.com/about/',
            text : 'Kate Swenson',
            suffix : ' (US)',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.theautismdad.com/',
            text : 'Rob Gorski',
            suffix : ' (US)',
            sub_links : []
          }
        ]
      },
      {
        subTitle : "To see experiences of neurodivergent adults who dedicate their lives to sharing their lived and living experience through their occupations as Youtubers (vloggers), writers, singers, coaches, educators, researchers and psychologists, please see:",
        links : [
          {
            prefix : '',
            href : 'https://christinakeeble.com/',
            text : 'Christina Keeble',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.samsonandbulluss.com/index.php/Home/about/Erin-Bulluss',
            text : 'Erin Bullus',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://josephinemoon.com/',
            text : 'Josephine Moon',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://www.kristyforbes.com.au/',
            text : 'Kristy Forbes',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://researchers.mq.edu.au/en/persons/melanie-heyworth',
            text : 'Melanie Heyworth',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://orionkelly.com.au/home',
            text : 'Orion Kelly',
            suffix : '',
            sub_links : []
          },
          {
            prefix : '',
            href : 'https://raelenedundon.com/',
            text : 'Ralene Dundon',
            suffix : '',
            sub_links : []
          }
        ]
      }
    ]
  },
  {
    title: "Community Resources",
    sections : [
      {
        subTitle: "",
        links: [
          {
            prefix: '',
            href: 'https://www.amaze.org.au/autismconnect/',
            text: 'Autism Connect',
            suffix: ' - National autism helpline',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.autismawareness.com.au/therapies/parents-carers',
            text: 'Autism Awareness Australia',
            suffix: ' - Therapies and support for parents and carers',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.amaze.org.au/',
            text: 'Amaze',
            suffix: ' - Support for autism families - Australia',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.autismspectrum.org.au/',
            text: 'Aspect',
            suffix: ' - Support for autism families - Australia',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://raisingchildren.net.au/autism',
            text: 'Raising Children’s Network',
            suffix: ' - Parenting support',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.differentjourneysautism.com/',
            text: 'Different journeys',
            suffix: ' - Autistic-led; carer support',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.yellowladybugs.com.au/',
            text: 'Yellow Ladybugs',
            suffix: ' - Celebration of autistic girls, women and gender diverse individuals',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://reframingautism.com.au/',
            text: 'Reframing Autism',
            suffix: ' - Autistic-led charity; resources and support',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://icannetwork.online/',
            text: 'ICAN Network',
            suffix: ' - Autistic-led; youth mentoring',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.afsaconnect.org.au/',
            text: 'Autism Family Support (ASSN) Victoria',
            suffix: ' - Autism Family Support - Victoria',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.valid.org.au/',
            text: 'VALID',
            suffix: ' - Advocacy Support',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.ndis.gov.au/',
            text: 'NDIS',
            suffix: ' - National Disability Insurance Scheme',
            sub_links: [
              {
                text: 'Funding for meals',
                href: 'https://ourguidelines.ndis.gov.au/supports-you-can-access-menu/nutrition-supports-including-meal-preparation/what-types-nutrition-supports-do-we-fund/how-can-i-use-my-meal-preparation-support-flexibly'
              },
              {
                text: 'Short term accommodation and respite',
                href: 'https://ourguidelines.ndis.gov.au/supports-you-can-access-menu/home-and-living-supports/short-term-accommodation-or-respite/what-short-term-accommodation-including-respite'
              },
              {
                text: 'Service/support coordination',
                href: 'https://www.ndis.gov.au/participants/using-your-plan/who-can-help-start-your-plan/support-coordination/finding-support-coordinator'
              },
              {
                text: 'Specialist disability accommodation',
                href: 'https://www.ndis.gov.au/providers/housing-and-living-supports-and-services/specialist-disability-accommodation'
              }
            ]
          },
          {
            prefix: '',
            href: 'https://hiddendisabilitiesstore.com/',
            text: 'Hidden Disabilities Sunflower',
            suffix: ' - Tool for sharing your hidden disability',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.gateways.com.au/',
            text: 'Gateways Support Services',
            suffix: ' - Disability and Autism Support: therapy, accommodation, support coordination, NDIS plan management',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.mytime.net.au/',
            text: 'MyTime',
            suffix: ' - Supporting parents and carers of children with disability',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.positivepartnerships.com.au/',
            text: 'Positive Partnerships',
            suffix: ' - Support school-aged students on the autism spectrum',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://reimagine.org.au/',
            text: 'REiMAGiNE Australia',
            suffix: ' - Information about early childhood intervention services (formerly known as Early Childhood Intervention Australia - ECIA)',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Connecting with the Autistic Community",
    sections: [
      {
        subTitle: "To look up where you and your child can participate in events run by autistic community organisations for the autistic community, check out:",
        links: [
          {
            prefix: 'Australian Capital Territory',
            href: 'https://mccg.org.au/services/autism-centre/',
            text: 'Mary Mead Autism Centre',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'New South Wales',
            href: 'https://www.autismspectrum.org.au/',
            text: 'Aspect',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Northern Territory',
            href: 'https://autismnt.org.au/',
            text: 'Autism NT',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Queensland',
            href: 'https://autismqld.com.au/',
            text: 'Autism QLD',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'South Australia',
            href: 'https://www.autismsa.org.au/',
            text: 'Autism SA',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Tasmania',
            href: 'https://www.raentasmania.com.au/',
            text: 'Regional Autistic Engagement Network',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Victoria',
            href: 'http://www.amaze.org.au/',
            text: 'AMAZE',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Victoria',
            href: 'https://aspergersvic.org.au/',
            text: 'Aspergers Victoria',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Western Australia',
            href: 'https://www.autism.org.au/',
            text: 'Autism Western Australia',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "For information on various autistic role models, check out the below:",
        links: [
          {
            prefix: '',
            href: 'https://www.chloehayden.com.au/',
            text: 'Chloe Hayden,',
            suffix: ' an autistic young person who is an advocate for the autistic community.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://icannetwork.online/about-i-can-network/',
            text: 'Chris Varney,',
            suffix: ' an autistic law graduate and founder/CEO of I CAN network, an autistic-led network.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://deanbeadle.wordpress.com/',
            text: 'Dean Beadle,',
            suffix: ' an autistic speaker, trainer and former journalist.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://laserbeakman.com/about-us/',
            text: 'Tim Sharp,',
            suffix: ' an autistic artist who created Laser Beak Man.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.jordynzimmerman.com/',
            text: 'Jordyn Zimmerman,',
            suffix: ' an autistic woman who advocates for non-speaking autistic people. ',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.youtube.com/watch?v=dKCdV20zLMs',
            text: 'Julia from Sesame Street,',
            suffix: ' an autistic muppet in the children’s TV show Sesame Street, who is friends with Elmo.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.youtube.com/watch?v=9CE3TUwnOs0',
            text: 'Carl from Arthur,',
            suffix: ' an 8-year-old autistic character in the children’s TV show Arthur.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.instagram.com/gretathunberg/?hl=en',
            text: 'Greta Thunberg,',
            suffix: ' an autistic young person who advocates for climate justice around the world.',
            sub_links: []
          },
          {
            prefix: '',
            href: 'http://www.templegrandin.com/',
            text: 'Temple Grandin,',
            suffix: ' an autistic author, speaker, and animal science professor.',
            sub_links: []
          }
        ]
      }
    ]
  },

  {
    title: "Conflict Resolution Resources",
    sections : [
      {
        subTitle: "For additional tips on resolving conflict, please check out:",
        links: [
          {
            prefix: 'Dishion, T. J., & Patterson, S. G. (2005). Parenting young children with love, encouragement, and limits. Research Press.',
            href: '',
            text: '',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
    },

  {
  title: "Depression and Anxiety Related Medical Conditions",
  sections : [
    {
      subTitle: "For information about medical conditions that may sometimes be related to depression or anxiety please check out:",
      links: [
        {
          prefix: '',
          href: 'https://www.healthdirect.gov.au/vitamin-b-deficiency',
          text: 'Vitamin B12 deficiency',
          suffix: '',
          sub_links: []
        },
        {
          prefix: '',
          href: 'https://www.healthdirect.gov.au/hypothyroidism',
          text: 'Hyperthyroidism',
          suffix: '',
          sub_links: []
        },
        {
          prefix: '',
          href: 'https://www.healthdirect.gov.au/hepatitis',
          text: 'Hepatitis',
          suffix: '',
          sub_links: []
        },
        {
          prefix: '',
          href: 'https://www.healthdirect.gov.au/hiv-infection-and-aids',
          text: 'HIV',
          suffix: '',
          sub_links: []
        },
        {
          prefix: '',
          href: 'https://www.healthdirect.gov.au/hypothyroidism',
          text: 'Hyperthyroidism',
          suffix: '',
          sub_links: []
        },
        {
          prefix: '',
          href: 'https://www.healthdirect.gov.au/heart-arrhythmias',
          text: 'Heart arrhythmias',
          suffix: '',
          sub_links: []
        },
        {
          prefix: '',
          href: 'https://www.health.harvard.edu/blog/depression-common-medication-side-effect-2018071614259',
          text: 'Medications that can cause depression',
          suffix: '',
          sub_links: []
        }
      ]
    }
  ]
  },
  {
    title: "Diagnosis of Autism",
    sections : [
      {
        subTitle: "For information about diagnosis of autism and sharing the diagnosis, check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.autismcrc.com.au/best-practice/assessment-and-diagnosis',
            text: 'National Guideline for Assessment and Diagnosis of Autism in Australia',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://raisingchildren.net.au/autism/learning-about-autism/about-autism/asd-overview',
            text: 'Autism signs & diagnosis in children',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'For the early days after diagnosis: ',
            href: 'https://www.autismspeaks.org/sites/default/files/100_Day_Tool_Kit_Young_Children.pdf',
            text: '100 Day Kit',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'For grandparents:',
            href: '',
            text: '',
            suffix: '',
            sub_links: [
              {
                text: 'Introduction to Autism Spectrum Disorders',
                href: 'https://www.amaze.org.au/wp-content/uploads/2022/12/Amaze-Information-Sheet-Information-for-grandparents.pdf'
              },
              {
                text: 'A Grandparent’s Guide to Autism',
                href: 'https://ganinfo.org/wp-content/uploads/2023/01/a_grandparents_guide_to_autism.pdf'
              }
            ]
          },
          {
            prefix: 'For sharing diagnosis: ',
            href: 'https://childmind.org/article/sharing-an-autism-diagnosis-with-family-and-friends/',
            text: 'Sharing an Autism Diagnosis With Family and Friends',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'For young siblings aged 5-10 years:',
            href: 'https://researchautism.org/resources/autism-my-sibling-and-me/',
            text: 'Autism, My Sibling, and Me',
            suffix: ' (Workbook)',
            sub_links: []
          },
          {
            prefix: 'For older siblings (pre-teens-teens):',
            href: 'https://researchautism.org/resources/life-as-an-autism-sibling-a-guide-for-teens/',
            text: 'Life as an Autism Sibling: A Guide for Teens',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.ndis.gov.au/',
            text: 'NDIS',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  }, 
  {
    title: "How to Help with Bullying",
    sections : [
      {
        subTitle: "For more information about how to help with bullying, please check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.vic.gov.au/my-child-being-bullied',
            text: 'My Child is Being Bullied',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Meal Planning & Recipe Ideas",
    sections : [
      {
        subTitle: "For information about planning meals and recipe ideas check out:",
        links: [
          {
            prefix: '',
            href: 'https://raisingchildren.net.au/school-age/nutrition-fitness',
            text: 'Raising Children Network - Food & Recipes',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.paprikaapp.com/',
            text: 'Paprika',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.mealime.com/',
            text: 'Mealime',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.yummly.com/',
            text: 'Yummly',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Mindfulness Apps",
    sections : [
      {
        subTitle: "For access to free daily mindfulness and meditation guides for adults and young people, download one of the below apps:",
        links: [
          {
            prefix: '',
            href: 'https://www.smilingmind.com.au/',
            text: 'Smiling Mind App',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.headspace.com/headspace-meditation-app',
            text: 'Headspace App',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://plumvillage.app/',
            text: 'Plum Village App',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  }, 
  {
    title: "Occupational Therapy",
    sections : [
      {
        subTitle: "To find an occupational therapist in Australia, please visit:",
        links: [
          {
            prefix: '',
            href: 'https://www.otaus.com.au',
            text: 'Occupational Therapy Australia',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Online Safety",
    sections : [
      {
        subTitle: "For information about online safety and how to use parental controls please check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.youtube.com/watch?v=DidqX2muXXM',
            text: 'A social story on internet safety',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.esafety.gov.au/parents/issues-and-advice/parental-controls#built-into-mobile-devices',
            text: 'Esafety Commissioner: Parental Controls',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://support.apple.com/en-au/HT201304',
            text: 'Apple iPhone - parental controls',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://support.google.com/googleplay/answer/1075738',
            text: 'Google Android - parental controls',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Pervasive Demand Avoidance",
    sections : [
      {
        subTitle: "For more information about Pervasive Demand Avoidance please check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.pdasociety.org.uk/',
            text: 'PDA Society website',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Relationship Support via Support Groups or Counselling",
    sections : [
      {
        subTitle: "",
        links: [
          {
            prefix: '',
            href: 'https://www.relationships.org.au/',
            text: 'Relationships Australia',
            suffix: ': please see website for phone numbers by state',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Responding to School Reluctance or Refusal",
    sections : [
      {
        subTitle: "For more information on strategies for school reluctance or refusal, check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.parentingstrategies.net/',
            text: 'Responding to school reluctance or refusal: Strategies for Parents',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Sensory Activities",
    sections : [
      {
        subTitle: "For an instructional video on how to do wheelbarrow walks with your child check out:",
        links: [
          {
            prefix: '',
            href: 'https://youtu.be/umtTnKXkqc8?si=rUJ3NY-TGskvGnvF',
            text: 'OTFC Wheelbarrow Walk',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Sleep Resources",
    sections : [
      {
        subTitle: "For more information about identifying sleep problems in children, please check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.mcri.edu.au/impact/watch-listen/listen/sleep-podcast',
            text: 'Murdoch Children\'s Research Institute Sleep Podcast',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  }, 
  {
    title: "Speech Pathology",
    sections : [
      {
        subTitle: "To find a speech therapist in Australia, please visit:",
        links: [
          {
            prefix: '',
            href: 'https://www.speechpathologyaustralia.org.au/Public/Shared_Content/Smart-Suite/Smart-Maps/Public/Find-a-Speech-Pathologist.aspx',
            text: 'Speech Pathology Australia',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Spoon Theory",
    sections : [
      {
        subTitle: "For more information about the Spoon Theory for autism check out:",
        links: [
          {
            prefix: '',
            href: 'https://neurodivergentinsights.com/blog/the-neurodivergent-spoon-drawer-spoon-theory-for-adhders-and-autists',
            text: 'Spoon Theory For Autism and ADHD: The Neurodivergent Spoon Drawer',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Stranger Safety",
    sections : [
      {
        subTitle: "For some video examples to share with your child about protecting yourself from strangers check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.youtube.com/watch?v=SY7RhBszp0k',
            text: 'Friendly\' strangers',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.youtube.com/watch?v=uAD17zMgjHc',
            text: 'Unwanted touch',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Supporting Your Child's Communication",
    sections: [
      {
        subTitle: "For more information about visual strategies, check out:",
        links: [
          {
            prefix: '',
            href: 'https://kwsa.org.au/',
            text: 'Key Word Signs',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://pecsaustralia.com/pecs/',
            text: 'Picture Exchange Communication Systems',
            suffix: ' (PECS)',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "For more information about assistive technology, check out:",
        links: [
          {
            prefix: '',
            href: 'https://goboardmaker.com/pages/boardmaker-7?tab=0',
            text: 'Boardmaker',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.cpec.org.au/podd',
            text: 'Pragmatic Organisation Dynamic Display',
            suffix: ' (PODD)',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "For more information about navigating change, check out:",
        links: [
          {
            prefix: '',
            href: 'https://carolgraysocialstories.com/',
            text: 'Social stories',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Support for Family Violence (1800Respect)",
    sections: [
      {
        subTitle: "For support with aggressive or violent behaviour in your home, please see:",
        links: [
          {
            prefix: '',
            href: 'https://www.1800respect.org.au/',
            text: 'National Domestic Family and Sexual Violence Counselling Service;',
            suffix: ' 1800 737 732  (24 hours, 7 days a week)',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Support Groups",
    sections: [
      {
        subTitle: "To find a support group or network of other parents and carers of autistic children, please see:",
        links: [
          {
            prefix: '',
            href: 'https://www.amaze.org.au/',
            text: 'AMAZE',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.carergateway.gov.au/',
            text: 'Carers Gateway',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://differentjourneysautism.com/',
            text: 'Different Journeys',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.kindred.org.au/',
            text: 'Kindred',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.mytime.net.au/',
            text: 'MyTime',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.peerconnect.org.au/',
            text: 'PeerConnect',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://www.yellowladybugs.com.au/',
            text: 'Yellow Ladybugs',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Support Social Interactions",
    sections: [
      {
        subTitle: "For more information about using social scripts to promote social understanding check out:",
        links: [
          {
            prefix: '',
            href: 'https://suelarkey.com.au/promoting-social-understanding-social-scripts/',
            text: 'Promoting Social Understanding – Social Scripts',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "For organised group activities to encourage interaction, check out:",
        links: [
          {
            prefix: 'School holiday camps:',
            href: 'https://www.flyingfox.org.au/',
            text: 'Flying Fox Camps',
            suffix: ' and Autism Camps Australia',
            sub_links: []
          },
          {
            prefix: 'Team sports:',
            href: 'https://www.specialolympics.com.au/',
            text: 'Special Olympics Australia',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Online groups:',
            href: 'https://icannetwork.online/online/?gad_source=1',
            text: 'I CAN Online',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Therapy groups:',
            href: 'https://www.autism.org.au/our-services/therapy-groups/therapy-groups-for-school-age-children/',
            text: 'Amaze Sports Academy and Social Magic',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Talking with Your Child",
    sections: [
      {
        subTitle: "For additional tips on talking to and listening to your child, check out:",
        links: [
          {
            prefix: 'Faber, A., & Mazlish, E. (2012). How to talk so kids will listen & listen so kids will talk. New York: Rawson, Wade Publishers.',
            href: '',
            text: '',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Traumatic Events Mental Health First Aid Guidelines",
    sections: [
      {
        subTitle: "For more information about helping a child after a traumatic event, please check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.mhfamiliesfriendstas.org.au/wp-content/uploads/2017/03/MHFA_child_guidelines_A4_2012_1.pdf',
            text: 'Mental Health First Aid Australia. Traumatic events first aid guidelines for assisting children.',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Understanding Neurodiversity",
    sections: [
      {
        subTitle: "For more information about understanding neurodiversity and some examples check out:",
        links: [
          {
            prefix: '',
            href: 'https://therapyfocus.org.au/on-the-blog/understanding-neurodiversity/',
            text: 'Understanding Neurodiversity',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://exceptionalindividuals.com/neurodiversity/',
            text: 'Neurodiversity & Neurodiversity: Meanings, Types & Examples',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  }
]

const pdfResources = [
  {
    title: "Please see the PDF resources linked throughout the PiP Kids-Autism modules below:",
    sections: [
      {
        subTitle: "Guide Your Child’s Behaviours",
        links: [
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/ABC+Data+Chart.pdf',
            text: 'ABC Data Chart',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "Encourage Good Health Habits",
        links: [
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/My+Child%E2%80%99s+Sleep+Diary.pdf',
            text: 'My Child’s Sleep Diary',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/Super+Sleeper+Award.pdf',
            text: 'Super Sleep Award',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/Food+Chart.pdf',
            text: 'Food Chart',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/My+Child_s+Physical+Activity+Schedule.pdf',
            text: 'My Child\'s Physical Activity Schedule',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "Establish Family Rules and Consequences",
        links: [
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/Rules+and+Consequences+Chart.pdf',
            text: 'Rules and Consequences Chart',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "Look After Yourself",
        links: [
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/My+Self+Care+Plan.pdf',
            text: 'My Self Care Plan',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/Building+Support+Strategies.pdf',
            text: 'Building Support Strategies',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "Support goal setting and problem solving",
        links: [
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/SMART+Goal+Setting.pdf',
            text: 'SMART Goal Setting',
            suffix: '',
            sub_links: []
          }
        ]
      },
      {
        subTitle: "Autism: The Fundamentals",
        links: [
          {
            prefix: '',
            href: 'https://induk-cms.s3.ap-southeast-2.amazonaws.com/pip-ka/files/My+Energy+Account.pdf',
            text: 'My Energy Account',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  }
];

const furtherReadings = [
  {
    title: "Further Reading and Resources for Guiding Your Child’s Behaviours",
    sections: [
      {
        subTitle: "From Anxiety to Meltdown: How Individuals on the Autism Spectrum Deal with Anxiety, Experience Meltdowns, Manifest Tantrums, and How You Can Intervene Effectively.",
        links: [
          {
            prefix: 'Lipsky, D. (2011). From Anxiety to Meltdown: How Individuals on the Autism Spectrum Deal with Anxiety, Experience Meltdowns, Manifest Tantrums, and How You Can Intervene Effectively: Jessica Kingsley Publishers.',
            href: '',
            text: '',
            suffix: '',
            sub_links: []
          },
        ]
      },
      {
        subTitle: "Understanding and Treating Self-Injurious Behavior in Autism: A Multi-Disciplinary Perspective",
        links: [
          {
            prefix: 'Edelson, S. M., Johnson, J., Bauman, M. L., Hardy, P. M., Miller, L. J., Durand, V. M., . . . Casanova, E. L. (2016). Understanding and Treating Self-Injurious Behavior in Autism: A Multi-Disciplinary Perspective: Jessica Kingsley Publishers.',
            href: '',
            text: '',
            suffix: '',
            sub_links: []
          },
        ]
      },
      {
        subTitle: "Guided Functional Behaviour Assessment Tool to help you understand, effectively respond to and prevent frequent minor behaviours:",
        links: [
          {
            prefix: '',
            href: 'https://autismhub.education.qld.gov.au/resources/functional-behaviour-assessment-tool',
            text: 'Guided Functional Behaviour Assessment Tool',
            suffix: '',
            sub_links: []
          }
        ]
      }
    ]
  },
  {
    title: "Further Reading and Resources for Supporting Your Child’s Sensory Needs",
    sections: [
      {
        subTitle: "For more information on sensory processing, check out:",
        links: [
          {
            prefix: '“What is sensory processing”, YouTube, uploaded by Differing Mids, 26 August 2022,',
            href: 'https://www.youtube.com/watch?v=V-kUKyfu0as',
            text: 'https://www.youtube.com/watch?v=V-kUKyfu0as',
            suffix: '(Permissions: YouTube Terms of Service)',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://research.chop.edu/car-autism-roadmap/stimming-what-is-it-and-does-it-matter',
            text: 'Stimming: What Is It and Does It Matter?',
            suffix: '',
            sub_links: []
          },
          {
            prefix: '',
            href: 'https://kids.frontiersin.org/articles/10.3389/frym.2021.558246',
            text: 'What is Interoception and Why is it Important?',
            suffix: '',
            sub_links: []
          },
          {
            prefix: 'Lean, C., Goodall, E., Leslie, M., Milanese, L., May, H., and Heays, D. (2019) ',
            href: 'https://www.education.sa.gov.au/docs/support-and-inclusion/student,-health-and-disability-support/interoception-301-activity-guide.pdf',
            text: 'Interoception Activity Guide 301',
            suffix: ', Department for Education, South Australia. ',
            sub_links: []
          },
        ]
      },
      {
        subTitle: "For more information on obsessions and repetitive behaviour check out:",
        links: [
          {
            prefix: '',
            href: 'https://www.autism.org.uk/advice-and-guidance/topics/behaviour/obsessions/all-audiences#H3_4',
            text: 'National Autistic Society: Obsessions and repetitive behaviour - a guide for all audiences',
            suffix: '',
            sub_links: []
          },
        ]
      }
    ]
  }
]